import React, {Component} from 'react';
import styled from "styled-components";
import Layout from "../components/layout"
import SEO from "../components/seo"
import COLORS from "../styles/colors";
import ImageXBg from "./../images/x-bg.webp";
import {Link} from "gatsby";
import SocialStudiesImage from "./../images/globe.png";
import ScienceImage from "./../images/science2.png";
import MathImage from "./../images/math.png";
import ElarImage from "./../images/elar.png";
import AllContentImage from "./../images/everything.png";
import TeacherInductionImage from "./../images/teacher.png";
import EnglishLearningImage from "./../images/esl.png";
import SpecialPopulationImage from "./../images/gifted.png";
import HighStakesImage from "./../images/high-stakes.png";

const InnerPage = styled.div`
    max-width: 1500px;
    margin: 0 auto;
    padding: 2rem;
`;

const Departments = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;
`;

const Department = styled(Link)`
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(33.33% - 2rem);
    margin: 1rem;
    text-decoration: none;
    background: url(${props => props.src}) no-repeat center center;
    background-size: cover;
    text-align: center;
    box-shadow: 3px 4px 8px #aaa;
    transition: transform 0.3s;
    min-height: 12rem;
    display: flex;
    text-align: center;
    align-items: flex-end;
    &:hover {
        transform: scale(1.025);
        cursor: pointer;

        div {
            font-size: 1.3rem;
            padding: 1rem 0;
        }
    }

    @media screen and (max-width: 960px) {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(50% - 2rem);
    }

    @media screen and (max-width: 640px) {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% - 2rem);
    }
`;

const DepartmentTitle = styled.div`
    transition: all 0.3s;
    background-color: ${COLORS.primaryColor};
    color: #fff;
    width: 100%;
    padding: 0.5rem;
`;


const StyledLink = styled(Link)`
    border: 2px solid ${COLORS.primaryColor};
    padding: 0.5rem 1rem;
    margin: 1rem;
    text-decoration: none;
    color: ${COLORS.primaryColor};
    transition: all  0.5s;
    display: inline-block;

    &:hover {
        background-color: ${COLORS.primaryColor};
        color: #fff;
    }
`;

const DepartmentText = styled.div`
    padding: 1rem 0;
    max-width: 960px;
    margin: 0 auto;
`;

const Wrapper = styled.div`
    background: url(${ImageXBg});
`;

class Catalog extends Component {
    render() {
        return (
            <Layout >
                <SEO title="Workshops" />
                <Wrapper>
                <InnerPage>
                    {/* <DepartmentText>
                        <h1>Workshops.</h1>
                        <p>Search either by department or interest to discover our wide array of offerings designed to meet your objectives and needs.</p>
                    </DepartmentText> */}
                    <Departments>
                        <Department src={SocialStudiesImage} to="/workshops/social-studies/">
                            <DepartmentTitle>Social Studies</DepartmentTitle>
                        </Department>
                        <Department src={ScienceImage} to="/workshops/science/">
                            <DepartmentTitle>Science</DepartmentTitle>
                        </Department>
                        <Department src={MathImage} to="/workshops/math/">
                            <DepartmentTitle>Math</DepartmentTitle>
                        </Department>
                        <Department src={ElarImage} to="/workshops/elar/">
                            <DepartmentTitle>ELAR</DepartmentTitle>
                        </Department>
                        <Department src={AllContentImage} to="/workshops/all-content/">
                            <DepartmentTitle>All Content</DepartmentTitle>
                        </Department>
                        <Department src={TeacherInductionImage} to="/workshops/teacher-induction/">
                            <DepartmentTitle>Asynchronous Student Modules</DepartmentTitle>
                        </Department>
                        <Department src={EnglishLearningImage} to="/workshops/english-learners/">
                            <DepartmentTitle>English Learners /<br />Emergent Bilinguals</DepartmentTitle>
                        </Department>
                        <Department src={SpecialPopulationImage} to="/workshops/special-populations/">
                            <DepartmentTitle>Advanced Academics /<br />Gifted and Talented</DepartmentTitle>
                        </Department>
                        <Department src={HighStakesImage} to="/workshops/high-stakes-testing/">
                            <DepartmentTitle>High-Stakes Testing</DepartmentTitle>
                        </Department>
                    </Departments>
                </InnerPage>
                </Wrapper>
            </Layout>
        )
    }
}

export default Catalog
